import { useWindowSize } from '@uidotdev/usehooks';
import { BREAKPOINTS } from '@theme_vars';

const useMediaQuery = () => {
    const size = useWindowSize();
    const isXl = size.width >= BREAKPOINTS.xl;
    const isLg = size.width >= BREAKPOINTS.lg && size.width < BREAKPOINTS.xl;
    const isMd = size.width >= BREAKPOINTS.md && size.width < BREAKPOINTS.xl;
    const isSm = size.width >= BREAKPOINTS.sm && size.width < BREAKPOINTS.md;
    const isXs = size.width >= BREAKPOINTS.xs && size.width < BREAKPOINTS.sm;
    const isMobile = size.width >= BREAKPOINTS.xs && size.width <= BREAKPOINTS.md - 1 && size?.width !== null;
    const isTablet = size.width >= BREAKPOINTS.md && size.width <= BREAKPOINTS.lg2 && size?.width !== null;
    const isDesktop = size.width > BREAKPOINTS.lg2 && size?.width !== null;
    let screen = '';
    if (isMobile) screen = 'mobile';
    if (isTablet) screen = 'tablet';
    if (isDesktop) screen = 'desktop';
    return {
        size,
        isXl,
        isLg,
        isMd,
        isSm,
        isXs,
        isMobile,
        isTablet,
        isDesktop,
        screen,
        screenWidth: size?.width || 0,
        screenHeight: size?.height || 0,
    };
};

export default useMediaQuery;
